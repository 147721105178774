
export const DEALER_API_DOMAIN= "https://gateway.cloud.volvotrucks.com" as string;
export const BACKEND_API_SECTION = 
(process.env.PUBLIC_URL == 'https://dealerlocatorfrontend-qa.cloud.volvotrucks.com' || 
process.env.PUBLIC_URL == '') ? 
"/dealerlocator-qa" : 
"/dealerlocator"; 

//console.log(BACKEND_API_SECTION, process.env.PUBLIC_URL);

export enum DEALER_API_ENDPOINTS {
    GET_DEALERS_LIST = BACKEND_API_SECTION + "/dealers" as any,
    GET_DEALERS_LIST_WITH_QUERY = BACKEND_API_SECTION + "/search" as any,
    GET_DEALER_DETAILS = BACKEND_API_SECTION + "/dealer" as any,
    GET_DEALER_SERVICES = BACKEND_API_SECTION + "/services" as any,
    GET_DEALER_TRANSLATIONS = '/dealerlocator/translations' as any,
    GET_CONTENT_ASSESTS = "/contentassets/" as any,
}

const LOCATIONIQ_AUTOCOMPLETE_ENDPOINT:string = 'https://api.locationiq.com/v1/autocomplete.php?key=';
const LOCATIONIQ_REVERSE_GEO_LOCATION_ENDPOINT:string = 'https://eu1.locationiq.com/v1/reverse.php?key=';
const GOOGLE_GET_DIRECTION:string = 'https://www.google.com/maps/dir/';


export const  apiEndpoints = {
    DEALER_API_ENDPOINTS: DEALER_API_ENDPOINTS,
    DEALER_API_DOMAIN: DEALER_API_DOMAIN,
    BACKEND_API_SECTION: BACKEND_API_SECTION,
    LOCATIONIQ_AUTOCOMPLETE_ENDPOINT:LOCATIONIQ_AUTOCOMPLETE_ENDPOINT,
    LOCATIONIQ_REVERSE_GEO_LOCATION_ENDPOINT:LOCATIONIQ_REVERSE_GEO_LOCATION_ENDPOINT,
    GOOGLE_GET_DIRECTION:GOOGLE_GET_DIRECTION,
};

export  default  apiEndpoints ;